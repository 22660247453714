import 'react-app-polyfill/ie11'; // For IE 11 support
import logger from 'saddlebag-logger';

import { loadExternalScripts } from './scriptLoader';

if (window.performance && window.performance.mark) {
  window.performance.mark('footer_start');
}

const component = 'footer';
const IDLE_TIMEOUT = 5000;

const main = async () => {
  const logError = (message) =>
    logger.logError({ message, level: 'error', component });

  if (process.env.NODE_ENV === 'production') {
    try {
      await loadExternalScripts();
    } catch (missing) {
      logError(missing, {
        component,
      });
    }
  }

  try {
    const { props } = window[component] || {};
    const React = await import(/* webpackMode: "eager" */ 'react');
    const ReactDOM = await import(/* webpackMode: "eager" */ 'react-dom');
    const { default: Footer } = await import(
      /* webpackMode: "eager" */ './components/Footer'
    );
    props.logger = logger;

    const container = document.getElementById(`${component}-root`);
    const footerElement = React.createElement(Footer, props);
    ReactDOM.hydrate(footerElement, container);

    if (window.performance && window.performance.mark) {
      window.performance.mark('footer_ready');
    }
  } catch (err) {
    if (process.env.NODE_ENV === 'production') {
      logError(err, { component });
    } else {
      throw err;
    }
  }
};

const { delayHydration } = window[component] || {};

if ('requestIdleCallback' in window && delayHydration) {
  requestIdleCallback(main, { timeout: IDLE_TIMEOUT });
} else {
  main();
}
